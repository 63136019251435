<template>
  <el-popover @show="handleShow" >
    <template #reference>
      <div class="cancelTag" ref="cancelTagRef"></div>
    </template>
    <p ref="PopoverPRef"></p>
  </el-popover>
</template>

<script>
  import { COURSE_Type, EcourseType } from '@/enums/common.js'
  export default {
    name:'subscribe',
    data(){
      return {
        applyTxt:'',
        TeacherCalendatInformation: JSON.parse(localStorage.getItem('TeacherCalendatInformation') || '[]') || COURSE_Type
      }
    },
    methods: {
      applyDataFn() {
        if (!this.tagDetail ||!this.TeacherCalendatInformation ||!this.TeacherCalendatInformation.detail) {
          return
        }
        this.applyTxt = this.monthCalendar.packageCourse1v1(this.TeacherCalendatInformation, {tagDetail:this.tagDetail})
        this.$refs.cancelTagRef.innerHTML = this.applyTxt
      },
      handleShow() {
        this.$nextTick(() => {
          this.$refs.PopoverPRef.innerHTML = this.applyTxt
        })
      }
    },
    mounted () {
      this.TeacherCalendatInformation = this.TeacherCalendatInformation.filter(child=>child.key === EcourseType.course1v1 )[0] || {}
      this.applyDataFn()
    },
    props:{
      tagDetail:{
        type:Object
      },
      monthCalendar:{
        type:Object
      }
    }
  }
</script>

<style lang="less" scoped>
  .cancelTag{
    width: calc( 100% - 10px);
    height: 24px;
    background: rgba(255, 199 ,79, .1);
    position: relative;
    border-radius: 8px;
    padding-left: 10px;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 5px;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: #FFC74F;
    }
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 24px;
  }
  /deep/ .time {
    color: #FFC74F;
    margin-right: 5px;
  }
</style>