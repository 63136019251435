<template>
  <div class="entity" ref="entityRef">
    <div
      class="listBox"
      v-for="(item, _index) in monthCalendar.CalendarData"
      :key="item.timestamp"
      :index="item.timestamp"
    >
      <div
        :class="[
          'reflection',
          item.isNow ? '' : 'pastDue',
          item.today ? 'today' : ''
        ]"
        :index="item.timestamp"
        @dblclick="
          () => {
            handleDbclickCell(item, item.fill);
          }
        "
      >
        <div class="date">
          {{ item.value }}
        </div>
        <div class="tagBox" :index="item.timestamp">
          <div v-if="item.activated" class="activated"></div>
          <div class="scrollBox" ref="scrollBoxRef" :index="item.timestamp">
            <template v-if="item.fill && showTag">
              <div
                v-for="child in item.fill
                  .slice(0, defaultShowNum)"
                :key="child.id"
                @click="
                  () => {
                    handleClickTag(child, item.fill);
                  }
                "
              >
                <NormalTag
                  :tagDetail="child"
                  v-if="child.type === EcourseType.vacate || child.type === EcourseType.makeUp || child.type === EcourseType.trial"
                  :monthCalendar="monthCalendar"
                />
              </div>
            </template>
          </div>
          <!-- <template v-if="item.fill && item.fill.length > defaultShowNum"> -->
          <el-popover
            :title="titlePackage(item.timestamp)"
            popper-class="moreTipBox"
            v-model="item.visible"
          >
            <span
              slot="reference"
              @click="handleShowPopover(item, _index)"
              class="moreTipTxt"
              v-if="item.fill && item.fill.length > defaultShowNum"
            >
                         还有{{ item.fill.length - defaultShowNum }}个日程

            </span>
            <MoreTip
              v-if="item.visible"
              :dayMore="item"
              ref="moreTipRef"
              :handleClickTag="handleClickTag"
              :monthCalendar="monthCalendar"
            />
          </el-popover>
          <!-- </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { COURSE_STATUS, EcourseType } from '@/enums/common.js'
  import NormalTag from './normalTag.vue'
  import MoreTip from './teacherMoreTip.vue'
  import { useEventBus } from '@/utils/bus'
  import moment from 'moment'

  export default {
    name: 'entity',
    data () {
      return {
        showTag: true,
        Bus: useEventBus(),
        defaultShowNum: 3,
        EcourseType,
        COURSE_STATUS,
        checkItem: {}
      }
    },
    methods: {
      handleDbclickCell ( dateObj, taglist ) {
        this.$emit('handleDbclickCell', dateObj, taglist)
      },
      setData () {
        if (this.monthCalendar.setCalendarFillData)
          this.monthCalendar.setCalendarFillData()
        this.$forceUpdate()
      },
      handleClickTag ( tagItem, tagList ) {
        this.$emit('handleClickTag', tagItem, tagList)
      },
      mouseUpEvent ( activated ) {
        this.$emit('mouseUpEvent', activated)
      },
      setDefaultShowNum () {
        this.defaultShowNum = Math.floor(
          (this.$refs.scrollBoxRef && this.$refs.scrollBoxRef[ 0 ]
            ? this.$refs.scrollBoxRef[ 0 ].clientHeight
            : 0) / 30
        )
      },
      set_MonthCalendar () {
      },
      titlePackage ( timestamp ) {
        if (!timestamp) return ''
        return moment.unix(timestamp).format('dddd')
      },
      handleShowPopover ( item, index ) {
        document.removeEventListener('click', () => {
        })
        this.checkItem = this.checkItem || {}
        this.checkItem.index = index
        item.visible = !item.visible
        this.checkItem = item
        this.$forceUpdate()
        this.$nextTick(() => {
          this.$refs.moreTipRef[ 0 ]
            ? this.$refs.moreTipRef[ 0 ].setdayMore(this.checkItem)
            : ''
          this.checkItem = null
        })
        // let _this = this
        // document.addEventListener('click',(e)=>{
        //   if(e.target.className === 'moreTipTxt'){
        //     _this.checkItem.visible = true
        //     _this.$nextTick(()=>{
        //       _this.$refs.moreTipRef[0] ? _this.$refs.moreTipRef[0].setdayMore(_this.checkItem) : ''
        //       _this.checkItem = null
        //     })
        //     return
        //   }
        //   if(_this.$refs.moreTipRef[0] && !_this.$refs.moreTipRef[0].$el.contains(e.target) ){
        //     _this.checkItem.visible = false
        //     // _this.$nextTick(()=>{
        //     //   _this.$refs.moreTipRef[0] ? _this.$refs.moreTipRef[0].setdayMore(_this.checkItem) : ''
        //     // })
        //   }
        // })
      }
    },
    components: {
      NormalTag,
      MoreTip
    },
    props: {
      monthCalendar: {
        type: Object
      }
    },
    mounted () {
      let _this = this
      this.Bus.$on('courseType', _obj => {
        if (!_obj) return
        _this.showTag = false
        _this.$nextTick(() => {
          _this.showTag = true
        })
      })
      this.Bus.$on('calendarEntityUpdate', obj => {
        if (!obj) return
        this.$forceUpdate()
      })
      this.$nextTick(() => {
        this.defaultShowNum = Math.floor(
          (this.$refs.scrollBoxRef && this.$refs.scrollBoxRef[ 0 ]
            ? this.$refs.scrollBoxRef[ 0 ].clientHeight
            : 0) / 30
        )
        const resizeObserver = new ResizeObserver(this.setDefaultShowNum)
        resizeObserver.observe(this.$refs.entityRef)
        if (this.monthCalendar.setupEventListeners)
          this.monthCalendar.setupEventListeners(
            this.$refs.entityRef,
            this.mouseUpEvent
          )
      })
    }
  }
</script>

<style lang="less" scoped>
  .entity {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    background-color: #f5f7fa;
    justify-content: space-around;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-left: 1px solid #f3f3f3;
    /* 火狐 */
    -moz-user-select: none;
    /* Safari 和 欧朋 */
    -webkit-user-select: none;
    /* IE10+ and Edge */
    -ms-user-select: none;
    /* Standard syntax 标准语法(谷歌) */
    user-select: none;

    .listBox {
      width: 14.2857%;
      padding-top: 9.2857%;
      position: relative;
      box-sizing: border-box;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #f3f3f3;

      .reflection {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;

        .date {
          position: absolute;
          right: 5px;
          top: 5px;
          font-size: 14px;
          color: #000;
          z-index: 1;
          line-height: 22px;
        }

        .tagBox {
          width: 100%;
          padding-top: 30px;
          height: calc(100% - 30px);

          .scrollBox {
            width: 100%;
            height: calc(100% - 25px);
            overflow: hidden;
          }

          .activated {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 30px;
            background-color: #51b6ff;
            opacity: 0.4;
            border: 1px solid #51b6ff;
            border-radius: 5px;
            z-index: 0;
          }

          /deep/ .moreTipTxt {
            display: inline-block;
            margin-bottom: 5px;
            height: 20px;
            line-height: 20px;
            cursor: pointer;
            font-size: 12px;
            padding-left: 5px;

            &:hover {
              color: #51b6ff;
            }
          }
        }
      }

      .pastDue {
        .date {
          color: #d6d6d6;
        }
      }

      .today {
        background: #f5f7fa;

        .date {
          background: #51b6ff;
          border-radius: 50%;
          text-align: center;
          color: #fff;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  /deep/ .moreTipBox {
    width: 100%;
    z-index: 2;
    position: relative;
  }
</style>
