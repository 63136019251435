export const EcalendarType = {
  week: 1,
  month: 2,
  teacherMonth: 3
};
export const COURSE_STATUS = {
  subscribe: 1,
  accomplish: 2,
  cancel: 3
};
export const EcourseType = {
  course1v1: 1,
  class: 2,
  vacate: 3,
  makeUp: 4,
  trial: 5
};
export const Eweek = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7
};
export const COURSE_Type = [
  {
    key: EcourseType.course1v1,
    value: "1v1",
    detail: [
      {
        key: "courseType",
        value: "课程类型",
        check: true
      },
      {
        key: "periods",
        value: "课时",
        check: true
      },
      {
        key: "user",
        value: "学生姓名",
        check: true
      },
      {
        key: "course_subject",
        value: "分类",
        check: false
      },
      {
        key: "category",
        value: "科目",
        check: false
      },
      {
        key: "status",
        value: "课程状态",
        check: false
      }
    ],
    checkAll: false
  },
  {
    key: EcourseType.class,
    value: "班课",
    checkAll: true,
    detail: [
      {
        key: "courseType",
        value: "课程类型",
        check: true
      },
      {
        key: "course.name",
        value: "课程名称",
        check: true
      },
      {
        key: "title",
        value: "小节名称",
        check: true
      }
    ]
  }
];
export const EdayType = {
  Am: 1,
  Pm: 2,
  Night: 3,
  Dawn: 4
};
export const EtimeInterval = [
  {
    value: "00:00 ~ 06:00",
    key: EdayType.Dawn,
    start: "00:01",
    end: "06:00"
  },
  {
    value: "06:00 ~ 12:00",
    key: EdayType.Am,
    start: "06:00",
    end: "12:00"
  },
  {
    value: "12:00 ~ 18:00",
    key: EdayType.Pm,
    start: "12:00",
    end: "18:00"
  },
  {
    value: "18:00 ~ 24:00",
    key: EdayType.Night,
    start: "18:00",
    end: "23:59"
  }
];
