<template>
  <div class="normalTag" :class="'normalTag-'+tagDetail.type">
    <el-popover >
      <template #reference>
        <div>
          <span class="time">
            {{ tagDetail.started_at ? moment.unix(tagDetail.started_at).format('HH:mm') : ''  }}-{{ tagDetail.ended_at ? moment.unix(tagDetail.ended_at).format('HH:mm') : '' }}
          </span><span v-if="tagDetail?.type === EcourseType.trial"
        >{{ tagDetail?.user?.name }} {{ tagDetail?.category?.name }}
        {{ tagDetail?.course_subject?.name }}</span>
          {{ tagDetail.remark }}
        </div>
      </template>
      <p>
        {{ tagDetail.started_at ? moment.unix(tagDetail.started_at).format('HH:mm') : ''  }}-{{ tagDetail.ended_at ? moment.unix(tagDetail.ended_at).format('HH:mm') : '' }}
        <span v-if="tagDetail?.type === EcourseType.trial"
        >{{ tagDetail?.user?.name }} {{ tagDetail?.category?.name }}
        {{ tagDetail?.course_subject?.name }}</span>{{ tagDetail.remark }}
      </p>
    </el-popover>
  </div>

</template>

<script>
  import moment from 'moment'
  import { EcourseType } from '@/enums/common'
  export default {
    name:'normalTag',
    computed: {
      EcourseType () {
        return EcourseType
      }
    },
    data(){
      return {
        moment
      }
    },
    props:{
      tagDetail:{
        type:Object
      },
      monthCalendar:{
        type:Object
      }
    },
    mounted () {
      this.TeacherCalendatInformation
    }
  }

</script>

<style lang="less" scoped>
  .normalTag{
    width: calc( 100% - 10px);
    height: 24px;
    background: rgba(190, 190, 190, .1);
    position: relative;
    border-radius: 8px;
    padding-left: 10px;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 5px;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: #BEBEBE;
    }
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 24px;
    .time{
      color: #BEBEBE;
      margin-right: 5px;
    }
  }
  .normalTag-4 {
    background: #eeeefd;

    &::before {
      background: #8181fe;
    }

    .time {
      color: #8181fe;
    }
  }

  .normalTag-5 {
    background: #f7f7e1;

    &::before {
      background: #bfbf00;
    }

    .time {
      color: #bfbf00;
    }
  }
</style>
