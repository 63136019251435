<template>
  <div class="calendar">
    <component
      :is="isDom"
      @handleDbclickCell="handleDbclickCell"
      @handleClickTag="handleClickTag"
      @mouseUpEvent="mouseUpEvent"
      v-bind="$attrs"
      ref="compenentRef"
    />
  </div>
</template>

<script>
import { EcalendarType } from "@/enums/common.js";
import month from "./components/month/month.vue";
import teacherMonth from "./components/month/teacherMonth.vue";
import week from "./components/week/week.vue";
export default {
  name: "",
  components: {
    month,
    teacherMonth,
    week
  },
  data() {
    return {
      isDom: "",
      interval: null
    };
  },
  props: {
    calendarType: {
      type: Number,
      default: EcalendarType.month
    }
  },
  methods: {
    assenble() {
      switch (this.calendarType) {
        case EcalendarType.week:
          this.isDom = "week";
          break;
        case EcalendarType.teacherMonth:
          this.isDom = "teacherMonth";
          break;
        default:
          this.isDom = "month";
      }
    },
    handleDbclickCell(dateObj, taglist) {
      this.$emit("handleDbclickCell", dateObj, taglist);
    },
    handleClickTag(tagItem, tagList) {
      this.$emit("handleClickTag", tagItem, tagList);
    },
    mouseUpEvent(activeData) {
      this.$emit("mouseUpEvent", activeData);
    },
    handleRenewal(timestamp, callback) {
      this.isApply(() => {
        if (this.$refs.compenentRef && this.$refs.compenentRef.handleRenewal) {
          this.$refs.compenentRef.handleRenewal(timestamp, callback);
        }
      });
    },
    isApply(callback) {
      if (!this.$refs.compenentRef) {
        this.interval = setInterval(() => {
          if (this.$refs.compenentRef) {
            callback();
            clearInterval(this.interval);
            return;
          }
        });
        return;
      }
      callback();
    },
    setFillData(data) {
      let _this = this;
      this.isApply(() => {
        _this.$refs.compenentRef.setData(data);
      });
    }
  },
  watch: {
    calendarType(val) {
      this.assenble();
    }
  },
  mounted() {
    this.assenble();
  }
};
</script>

<style lang="less" scoped>
.calendar {
  width: 100%;
  height: 100%;
}
</style>
